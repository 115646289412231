<template>
  <layout-app>
    <div class="card border-0">
      <div class="card-body">
        <div class="d-flex flex-column flex-sm-row gap-2 gap-md-3">
          <CardBarang type="pemasukan" :value="data.totalPemasukan" />
          <CardBarang type="pengeluaran" :value="data.totalPengeluaran" />
        </div>
        <br />
        <div class="p-3">
          <p class="fw-semibold">Riwayat</p>
          <v-data-table
            :headers="headers"
            :items="data.recentHistory"
            :loading="isLoading"
            :options.sync="optionsTable"
          >
            <template v-slot:[`item.date`]="{ item }">
              {{ moment(item.date).format("DD MMMM YYYY") }}
            </template>
            <template v-slot:[`item.dokumen`]="{ item }">
              <a
                :href="linkDownload(item.docs_filepath)"
                :download="item.docs_number + '.pdf'"
              >
                <i class="fa fa-download"></i> Download
              </a>
            </template>
          </v-data-table>
        </div>
      </div>
    </div>
  </layout-app>
</template>

<script>
import moment from "moment";
import LayoutApp from "@/layouts/layout-app.vue";

export default {
  name: "Home",
  components: {
    CardBarang: () => import("@/components/molecules/card-barang/index.vue"),
    LayoutApp,
  },
  data() {
    return {
      headers: [
        { text: "No. Surat Permohonan", value: "no_surat_mohon" },
        { text: "Tanggal", value: "created_at" },
        { text: "Seri", value: "seri" },
        { text: "HS Code", value: "hs" },
        { text: "Uraian", value: "uraian" },
        { text: "Negara Asal", value: "negara_asal" },
        { text: "Pelabuhan Tujuan", value: "pelabuhan_tujuan" },
        {
          text: "Volume Sesuai Kuota (Dalam satuan Besar) /Satuan",
          value: "kuota",
        },
        {
          text: "Volume Sesuai Kuota (Dalam satuan Kecil) /Satuan",
          value: "kuota_terkecil",
        },
        {
          text: "Realisasi (dalam satuan besar) /Satuan",
          value: "volume",
        },
        {
          text: "Realisasi (dalam satuan kecil) /Satuan",
          value: "volume_terkecil",
        },
        { text: "Nilai Sesuai PIB /Kurs", value: "nilai" },
      ],
      baseUrl: "https://api.batam-itinventory.com",
      linkDownload: (file) =>
        `${this.baseUrl}/documents/${file.split("/").pop()}`,
      moment,
    };
  },
  computed: {
    data() {
      return this.$store.state.home.data;
    },
    isLoading() {
      return this.$store.state.home.isLoading;
    },
    optionsTable: {
      get() {
        return this.$store.state.home.optionsTable;
      },
      set(value) {
        this.$store.commit("SET_OPTIONS_TABLE_HOME", value);
      },
    },
  },
  created() {
    this.$store.dispatch("GetHome");
  },
};
</script>
