<template>
  <v-app>
    <v-main class="bg-primary px-3">
      <slot />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "AuthLayout",
};
</script>
