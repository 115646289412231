<template>
  <layout-auth>
    <div class="row h-100 justify-content-center align-items-center">
      <div class="col-12 col-md-7 col-lg-5 col-xxl-3">
        <v-form ref="initialReport" @submit.prevent="handleSubmit" class="card">
          <div class="card-body py-10">
            <router-link
              to="/"
              class="d-flex align-items-center justify-content-center mb-10"
            >
              <img src="@/assets/logo.svg" width="40" alt="logo" />
              <h1 class="mb-0 h5 ml-3 fw-bold">IT Inventory</h1>
            </router-link>
            <div class="row">
              <div class="col-12">
                <v-text-field
                  outlined
                  dense
                  placeholder="Password"
                  prepend-inner-icon="mdi-lock-outline"
                  :append-icon="showOldPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showOldPassword ? 'text' : 'password'"
                  @click:append="showOldPassword = !showOldPassword"
                  v-model="old_password"
                  :rules="[
                    (value) => {
                      return genericRequiredRule(value, 'Password');
                    },
                  ]"
                />
              </div>
              <div class="col-12">
                <v-text-field
                  outlined
                  dense
                  placeholder="New Password"
                  prepend-inner-icon="mdi-lock-outline"
                  :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showNewPassword ? 'text' : 'password'"
                  @click:append="showNewPassword = !showNewPassword"
                  v-model="new_password"
                  :rules="[
                    (value) => {
                      return genericRequiredRule(value, 'Password');
                    },
                  ]"
                />
              </div>
              <div class="col-12">
                <v-text-field
                  outlined
                  dense
                  placeholder="Confirm New Password"
                  prepend-inner-icon="mdi-lock-outline"
                  :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showConfirmPassword ? 'text' : 'password'"
                  @click:append="showConfirmPassword = !showConfirmPassword"
                  v-model="confirm_password"
                  :rules="[
                    (value) => {
                      return genericRequiredRule(value, 'Password');
                    },
                    (value) => {
                      return genericMatchRule(
                        value,
                        'Password',
                        this.new_password
                      );
                    },
                  ]"
                />
              </div>

              <div class="col-12">
                <v-btn class="btn btn-primary w-100" type="submit">
                  Ganti Password
                </v-btn>
              </div>
            </div>
          </div>
        </v-form>
      </div>
    </div>
  </layout-auth>
</template>

<script>
import LayoutAuth from "../../layouts/layout-auth.vue";
import { ValidationRules } from "@/mixins/validation-rules.js";

export default {
  name: "ForgotPassword",
  mixins: [ValidationRules],
  components: {
    LayoutAuth,
  },
  data() {
    return {
      showOldPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
    };
  },
  computed: {
    old_password: {
      get() {
        return this.$store.state.app.form_change_password.old_password;
      },
      set(value) {
        this.$store.commit("SET_FORM_CHANGE_PASSWORD_APP", {
          key: "old_password",
          value,
        });
      },
    },
    new_password: {
      get() {
        return this.$store.state.app.form_change_password.new_password;
      },
      set(value) {
        this.$store.commit("SET_FORM_CHANGE_PASSWORD_APP", {
          key: "new_password",
          value,
        });
      },
    },
    confirm_password: {
      get() {
        return this.$store.state.app.form_change_password.confirm_password;
      },
      set(value) {
        this.$store.commit("SET_FORM_CHANGE_PASSWORD_APP", {
          key: "confirm_password",
          value,
        });
      },
    },
  },
  methods: {
    handleSubmit() {
      if (this.$refs.initialReport.validate()) {
        this.$store.dispatch("ChangePassword").then((res) => {
          if (res) {
            this.$refs.initialReport.reset();

            this.$router.push("/");
          }
        });
      }
    },
  },
};
</script>
