var render = function render(){var _vm=this,_c=_vm._self._c;return _c('layout-auth',[_c('div',{staticClass:"row h-100 justify-content-center align-items-center"},[_c('div',{staticClass:"col-12 col-md-7 col-lg-5 col-xxl-3"},[_c('v-form',{ref:"initialReport",staticClass:"card",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"card-body py-10"},[_c('router-link',{staticClass:"d-flex align-items-center justify-content-center mb-10",attrs:{"to":"/"}},[_c('img',{attrs:{"src":require("@/assets/logo.svg"),"width":"40","alt":"logo"}}),_c('h1',{staticClass:"mb-0 h5 ml-3 fw-bold"},[_vm._v("IT Inventory")])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('v-text-field',{attrs:{"outlined":"","dense":"","placeholder":"Password","prepend-inner-icon":"mdi-lock-outline","append-icon":_vm.showOldPassword ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showOldPassword ? 'text' : 'password',"rules":[
                  (value) => {
                    return _vm.genericRequiredRule(value, 'Password');
                  },
                ]},on:{"click:append":function($event){_vm.showOldPassword = !_vm.showOldPassword}},model:{value:(_vm.old_password),callback:function ($$v) {_vm.old_password=$$v},expression:"old_password"}})],1),_c('div',{staticClass:"col-12"},[_c('v-text-field',{attrs:{"outlined":"","dense":"","placeholder":"New Password","prepend-inner-icon":"mdi-lock-outline","append-icon":_vm.showNewPassword ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showNewPassword ? 'text' : 'password',"rules":[
                  (value) => {
                    return _vm.genericRequiredRule(value, 'Password');
                  },
                ]},on:{"click:append":function($event){_vm.showNewPassword = !_vm.showNewPassword}},model:{value:(_vm.new_password),callback:function ($$v) {_vm.new_password=$$v},expression:"new_password"}})],1),_c('div',{staticClass:"col-12"},[_c('v-text-field',{attrs:{"outlined":"","dense":"","placeholder":"Confirm New Password","prepend-inner-icon":"mdi-lock-outline","append-icon":_vm.showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showConfirmPassword ? 'text' : 'password',"rules":[
                  (value) => {
                    return _vm.genericRequiredRule(value, 'Password');
                  },
                  (value) => {
                    return _vm.genericMatchRule(
                      value,
                      'Password',
                      this.new_password
                    );
                  },
                ]},on:{"click:append":function($event){_vm.showConfirmPassword = !_vm.showConfirmPassword}},model:{value:(_vm.confirm_password),callback:function ($$v) {_vm.confirm_password=$$v},expression:"confirm_password"}})],1),_c('div',{staticClass:"col-12"},[_c('v-btn',{staticClass:"btn btn-primary w-100",attrs:{"type":"submit"}},[_vm._v(" Ganti Password ")])],1)])],1)])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }