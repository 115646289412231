<template>
  <layout-app>
    <HeaderTitle title="Negara" subtitle="Referensi / Negara" />

    <div class="card border-0 p-3">
      <div class="card-body">
        <div class="row justify-content-end">
          <v-text-field
            outlined
            dense
            prepend-inner-icon="mdi-magnify"
            placeholder="Cari..."
            style="max-width: 300px"
            v-model="optionsTable.search"
          />
        </div>
        <v-data-table
          :headers="headers"
          :items="reports"
          :loading="isLoading"
          :options.sync="optionsTable"
          :search="optionsTable.search"
        >
        </v-data-table>
      </div>
    </div>
  </layout-app>
</template>

<script>
import LayoutApp from "@/layouts/layout-app.vue";

export default {
  name: "NegaraPage",
  components: {
    LayoutApp,
    HeaderTitle: () => import("@/components/molecules/header-title"),
  },
  data() {
    return {
      headers: [
        { text: "No", value: "no" },
        { text: "Negara Code", value: "country_code" },
        { text: "Negara", value: "country_name" },
      ],
    };
  },
  computed: {
    reports() {
      return this.$store.state.negara.reports;
    },
    isLoading() {
      return this.$store.state.negara.isLoading;
    },
    optionsTable: {
      get() {
        return this.$store.state.negara.optionsTable;
      },
      set(value) {
        this.$store.commit("SET_OPTIONS_TABLE_NEGARA", value);
      },
    },
  },
  created() {
    this.$store.dispatch("GetNegara");
  },
};
</script>
