<template>
  <layout-auth>
    <div class="d-flex h-100 justify-content-center align-items-center">
      <div class="card" style="max-width: 400px">
        <div class="card-body py-10">
          <router-link
            to="/"
            class="d-flex align-items-center justify-content-center mb-10"
          >
            <img src="@/assets/logo.svg" width="40" alt="logo" />
            <h1 class="mb-0 h5 ml-3 fw-bold">IT Inventory</h1>
          </router-link>
          <v-form
            class="row"
            ref="initialReport"
            @submit.prevent="handleSubmit"
          >
            <div class="col-12">
              <v-text-field
                outlined
                dense
                placeholder="Email Address / NPWP"
                prepend-inner-icon="mdi-account-outline"
                v-model="uid"
                :rules="[
                  (value) => {
                    return genericRequiredRule(value, 'Email Address / NPWP');
                  },
                ]"
              />
            </div>
            <div class="col-12">
              <v-text-field
                outlined
                dense
                placeholder="Password"
                prepend-inner-icon="mdi-lock-outline"
                :append-icon="isShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="isShowPassword ? 'text' : 'password'"
                @click:append="isShowPassword = !isShowPassword"
                v-model="password"
                :rules="[
                  (value) => {
                    return genericRequiredRule(value, 'Password');
                  },
                ]"
              />
            </div>
            <!-- <div class="col-12">
              <v-text-field
                outlined
                dense
                placeholder="Code"
                prepend-inner-icon="mdi-lock-outline"
                messages="Code is required when you login as not User"
                v-model="code"
              />
            </div> -->
            <!-- <div class="col-12 mb-3">
                <div class="text-right small">
                  <router-link to="/forgot-password"
                    >Lupa password?</router-link
                  >
                </div>
              </div> -->
            <div class="col-12">
              <v-btn
                type="submit"
                class="btn btn-primary w-100"
                :loading="isLoading"
              >
                Login
              </v-btn>
            </div>
          </v-form>
        </div>
      </div>
    </div>
  </layout-auth>
</template>

<script>
import { ValidationRules } from "@/mixins/validation-rules.js";
import LayoutAuth from "../../layouts/layout-auth.vue";

export default {
  name: "Login",
  mixins: [ValidationRules],
  components: {
    LayoutAuth,
  },
  data() {
    return {
      isShowPassword: false,
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.app.isLoading;
    },
    uid: {
      get() {
        return this.$store.state.app.form_login.uid;
      },
      set(value) {
        this.$store.commit("SET_FORM_LOGIN_APP", {
          key: "uid",
          value,
        });
      },
    },
    password: {
      get() {
        return this.$store.state.app.form_login.password;
      },
      set(value) {
        this.$store.commit("SET_FORM_LOGIN_APP", {
          key: "password",
          value,
        });
      },
    },
    code: {
      get() {
        return this.$store.state.app.form_login.code;
      },
      set(value) {
        this.$store.commit("SET_FORM_LOGIN_APP", {
          key: "code",
          value,
        });
      },
    },
  },
  methods: {
    handleSubmit() {
      if (this.$refs.initialReport.validate()) {
        this.$store.dispatch("Login");
      }
    },
  },
};
</script>
