<template>
  <layout-app>
    <div class="d-flex flex-column flex-sm-row justify-content-between">
      <HeaderTitle title="User" subtitle="User" />

      <div class="mb-5 mb-sm-0">
        <v-btn class="btn btn-primary" @click="handleModalForm">
          <i class="fa-solid fa-plus"></i>
          Tambah
        </v-btn>
      </div>
    </div>
    <div class="card border-0 p-3">
      <div class="card-body py-5">
        <div class="row justify-content-end">
          <div class="col-12 col-sm-5 col-lg-4 col-xl-3">
            <v-text-field
              outlined
              dense
              prepend-inner-icon="mdi-magnify"
              placeholder="Cari user..."
              v-model="optionsTable.search"
            />
          </div>
        </div>
        <v-data-table
          :headers="headers"
          :items="reports"
          :loading="isLoading"
          :options.sync="optionsTable"
          :search="optionsTable.search"
        >
          <template v-slot:[`item.status`]="{ item }">
            <v-chip :color="item.is_active ? 'success' : 'error'" small>
              {{ item.is_active ? "Active" : "Inactive" }}
            </v-chip>
          </template>
          <template v-slot:[`item.address`]="{ item }">
            <span v-if="item.address.length < 30"> {{ item.address }} </span>
            <span v-else> {{ item.address.substring(0, 30) }}... </span>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  class="btn btn-outline-primary"
                  v-bind="attrs"
                  v-on="on"
                >
                  <span class="fw-light">Action</span>
                  <i class="fa-solid fa-chevron-down small"></i>
                </v-btn>
              </template>
              <v-list style="min-width: 200px">
                <v-list-item @click="handleUpdate(item.id)">
                  <v-list-item-title class="text-primary text-opacity-75">
                    <i class="fa-regular fa-pen-to-square small mr-2"></i>
                    <span class="small">Edit</span>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="handleDelete(item.id)">
                  <v-list-item-title class="text-primary text-opacity-75">
                    <i class="fa-regular fa-trash-can small mr-2"></i>
                    <span class="small">Hapus</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </div>
    </div>

    <v-dialog v-model="modalForm" persistent max-width="500">
      <UserForm @handleModalForm="handleModalForm" />
    </v-dialog>
  </layout-app>
</template>

<script>
import Swal from "sweetalert2";
import LayoutApp from "../../layouts/layout-app.vue";

export default {
  name: "BarangPage",
  components: {
    LayoutApp,
    HeaderTitle: () => import("@/components/molecules/header-title"),
    UserForm: () => import("@/components/organisms/user-form"),
  },
  data() {
    return {
      headers: [
        { text: "No", value: "no" },
        { text: "Nama", value: "name" },
        { text: "NPWP", value: "npwp" },
        { text: "Email", value: "email" },
        // { text: "No Telp", value: "carbs" },
        { text: "Alamat", value: "address" },
        { text: "Role", value: "role" },
        { text: "Status", value: "status" },
        { text: "Action", value: "action" },
      ],
      modalForm: false,
    };
  },
  computed: {
    reports() {
      return this.$store.state.user.reports;
    },
    isLoading() {
      return this.$store.state.user.isLoading;
    },
    optionsTable: {
      get() {
        return this.$store.state.user.optionsTable;
      },
      set(value) {
        this.$store.commit("SET_OPTIONS_TABLE_USER", value);
      },
    },
  },
  methods: {
    handleModalForm() {
      this.modalForm = !this.modalForm;
    },
    handleDelete(id) {
      Swal.fire({
        title: "Apakah anda yakin?",
        text: "Data yang dihapus tidak dapat dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya, hapus!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("DeleteUser", id);
        }
      });
    },
    handleUpdate(id) {
      this.$store.commit("SET_IS_UPDATE_USER", id);
      this.$store.dispatch("SetFormUpdateUser", id);
      this.handleModalForm();
    },
  },
  created() {
    this.$store.dispatch("GetAllUsers");
  },
};
</script>
