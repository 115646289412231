import axios from "axios";
import catchUnauthorized from "../../utils/catch-unauthorized";
const apiUrl = process.env.VUE_APP_API_URL;
// import Swal from "sweetalert2";

const barang = {
  state: {
    isLoading: false,
    optionsTable: {
      page: 1,
      itemsPerPage: 10,
      search: "",
    },
    reports: [],
    report: {},
  },
  mutations: {
    SET_OPTIONS_TABLE_BARANG(state, payload) {
      state.optionsTable = Object.assign({}, payload);
    },
    SET_LOADING_BARANG(state, payload) {
      state.isLoading = payload;
    },
    SET_REPORTS_BARANG(state, payload) {
      state.reports = payload;
    },
    SET_REPORT_BARANG(state, payload) {
      state.report = payload;
    },
  },
  actions: {
    async GetBarang(context) {
      context.commit("SET_LOADING_BARANG", true);
      try {
        const result = await axios({
          url: `${apiUrl}/barang`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        result.data.data.forEach((item, index) => {
          item.no = index + 1;
        });

        context.commit("SET_REPORTS_BARANG", result.data.data);
      } catch (error) {
        catchUnauthorized(error, context);
      } finally {
        context.commit("SET_LOADING_BARANG", false);
      }
    },
    async GetBarangHistory(context, payload) {
      context.commit("SET_LOADING_BARANG", true);
      try {
        const result = await axios({
          url: `${apiUrl}/barang/history/${payload.id_ijin}/${payload.seri}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        context.commit("SET_REPORT_BARANG", result.data.data);
      } catch (error) {
        catchUnauthorized(error, context);
      } finally {
        context.commit("SET_LOADING_BARANG", false);
      }
    },
  },
};

export default barang;
