<template>
  <layout-app>
    <HeaderTitle title="Realisasi" subtitle="Realisasi" />

    <div class="card border-0 p-3">
      <div class="card-body">
        <div class="row justify-content-end">
          <v-text-field
            outlined
            dense
            prepend-inner-icon="mdi-magnify"
            placeholder="Cari..."
            style="max-width: 300px"
            v-model="optionsTable.search"
          />
        </div>
        <v-data-table
          :headers="headers"
          :items="reports"
          :loading="isLoading"
          :options.sync="optionsTable"
          :search="optionsTable.search"
          class="table-striped"
        >
          <template v-slot:[`item.perusahaan`]="{ item }">
            <p class="mb-0 fs-14">{{ item.nama_persh }}</p>
            <p class="mb-0 fs-13 fw-light">
              No. Daftar: {{ item.no_pendaftaran }}
            </p>
          </template>
          <template v-slot:[`item.dokumen`]="{ item }">
            <p class="mb-0 fs-14">Layan : {{ item.urailayan }}</p>
            <p class="my-1 fs-13 fw-light">
              Tgl. {{ moment(item.tglawal) }} ->
              {{ moment(item.tglakhir) }}
            </p>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn
              small
              class="btn btn-outline-primary"
              @click="handleRealisasi(item.id_ijin)"
            >
              <i class="fa-regular fa-pen-to-square"></i>
              <span class="small">Realisasi</span>
            </v-btn>
          </template>
        </v-data-table>
      </div>
    </div>
  </layout-app>
</template>

<script>
import LayoutApp from "@/layouts/layout-app.vue";
import moment from "moment";

export default {
  name: "RealisasiPage",
  components: {
    LayoutApp,
    HeaderTitle: () => import("@/components/molecules/header-title"),
  },
  data() {
    return {
      headers: [
        { text: "Perusahaan", value: "perusahaan" },
        { text: "Dokumen", value: "dokumen" },
        // { text: "Tgl. Dokumen", value: "tgl_dokumen", align: "right" },
        { text: "Perijinan", value: "no_ijin" },
        { text: "Tgl.Perijinan", value: "tgl_daftar" },
        { text: "Action", value: "action", sortable: false },
      ],
      moment: (date) => moment(date).format("DD-MM-YYYY"),
    };
  },
  computed: {
    reports() {
      return this.$store.state.realisasi.reports;
    },
    isLoading() {
      return this.$store.state.realisasi.isLoading;
    },
    optionsTable: {
      get() {
        return this.$store.state.realisasi.optionsTable;
      },
      set(value) {
        this.$store.commit("SET_OPTIONS_TABLE_REALISASI", value);
      },
    },
  },
  methods: {
    handleRealisasi(id_ijin) {
      this.$router.push(`/realisasi/form/${id_ijin}`);
    },
  },
  created() {
    this.$store.dispatch("GetRealisasi");
  },
};
</script>
