var render = function render(){var _vm=this,_c=_vm._self._c;return _c('layout-auth',[_c('div',{staticClass:"d-flex h-100 justify-content-center align-items-center"},[_c('div',{staticClass:"card",staticStyle:{"max-width":"400px"}},[_c('div',{staticClass:"card-body py-10"},[_c('router-link',{staticClass:"d-flex align-items-center justify-content-center mb-10",attrs:{"to":"/"}},[_c('img',{attrs:{"src":require("@/assets/logo.svg"),"width":"40","alt":"logo"}}),_c('h1',{staticClass:"mb-0 h5 ml-3 fw-bold"},[_vm._v("IT Inventory")])]),_c('v-form',{ref:"initialReport",staticClass:"row",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"col-12"},[_c('v-text-field',{attrs:{"outlined":"","dense":"","placeholder":"Email Address / NPWP","prepend-inner-icon":"mdi-account-outline","rules":[
                (value) => {
                  return _vm.genericRequiredRule(value, 'Email Address / NPWP');
                },
              ]},model:{value:(_vm.uid),callback:function ($$v) {_vm.uid=$$v},expression:"uid"}})],1),_c('div',{staticClass:"col-12"},[_c('v-text-field',{attrs:{"outlined":"","dense":"","placeholder":"Password","prepend-inner-icon":"mdi-lock-outline","append-icon":_vm.isShowPassword ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.isShowPassword ? 'text' : 'password',"rules":[
                (value) => {
                  return _vm.genericRequiredRule(value, 'Password');
                },
              ]},on:{"click:append":function($event){_vm.isShowPassword = !_vm.isShowPassword}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c('div',{staticClass:"col-12"},[_c('v-btn',{staticClass:"btn btn-primary w-100",attrs:{"type":"submit","loading":_vm.isLoading}},[_vm._v(" Login ")])],1)])],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }