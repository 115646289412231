import axios from "axios";
const apiUrl = process.env.VUE_APP_API_URL;
import Swal from "sweetalert2";
import router from "@/routes";
import catchUnauthorized from "../../utils/catch-unauthorized";

const app = {
  state: {
    layout: "app",
    user: "",
    token: "",
    isLoading: false,
    form_login: {
      uid: "",
      password: "",
      code: "",
    },
    form_change_password: {
      old_password: "",
      new_password: "",
      confirm_password: "",
    },
  },
  mutations: {
    SET_LAYOUT_APP(state, layout) {
      state.layout = layout;
    },
    SET_LOADING_APP(state, payload) {
      state.payload = payload;
    },
    SET_USER_APP(state, user) {
      state.user = user;
    },
    SET_TOKEN_APP(state, token) {
      state.token = token;
    },
    SET_FORM_LOGIN_APP(state, payload) {
      state.form_login[payload.key] = payload.value;
    },
    SET_FORM_CHANGE_PASSWORD_APP(state, payload) {
      state.form_change_password[payload.key] = payload.value;
    },
  },
  actions: {
    async Login(context) {
      context.commit("SET_LOADING_APP", true);
      try {
        const result = await axios({
          url: `${apiUrl}/login`,
          method: "POST",
          data: context.state.form_login,
        });

        context.commit("SET_USER_APP", result.data.data.user);
        localStorage.setItem("user", JSON.stringify(result.data.data.user));

        context.commit("SET_TOKEN_APP", result.data.data.token);
        localStorage.setItem("token", result.data.data.token);

        context.state.form_login = {
          uid: "",
          password: "",
        };

        router.push("/");
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      } finally {
        context.commit("SET_LOADING_APP", false);
      }
    },
    async ChangePassword(context) {
      context.commit("SET_LOADING_APP", true);
      try {
        const result = await axios({
          url: `${apiUrl}/change-password`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
          data: context.state.form_change_password,
        });

        Swal.fire({
          title: "Success",
          text: result.data.message,
          icon: "success",
        });

        return true;
      } catch (error) {
        catchUnauthorized(error, context);
        Swal.fire({
          title: "Oops...",
          text: error.response.data.message,
          icon: "error",
        });
      } finally {
        context.commit("SET_LOADING_APP", false);
      }
    },
  },
};

export default app;
