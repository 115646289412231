import axios from "axios";
import catchUnauthorized from "../../utils/catch-unauthorized";
import moment from "moment";
const apiUrl = process.env.VUE_APP_API_URL;
import Swal from "sweetalert2";

const form_komoditas = {
  seri: "",
  hs: "",
  uraian: "",
  satuan: "",
  satuan_terkecil: "",
  negara_asal: "",
  pelabuhan_tujuan: "",
  rate_nilai_tukar: "",
  kode_kurs: "",
  harga_satuan: "",
  totalharga: "",

  kuota: "",
  kuota_terkecil: "",

  nilai: "",
  volume: "",
  volume_terkecil: "",
  hargasatuan: "",
  kurs_pib: "",
  upload_id: "",
};

const realisasi = {
  state: {
    isLoading: false,
    optionsTable: {
      page: 1,
      itemsPerPage: 10,
      search: "",
    },
    reports: [],
    report: {},
    form_komoditas: { ...form_komoditas },
    check_stock: {
      is_realisasi: false,
      stock: "",
      stock_terkecil: "",
      sisa_stock: "",
      sisa_stock_terkecil: "",
    },
    data_realisasi_not_sent: [],
    data_realisasi: [],
    data_realisasi_persentasi: [],
  },
  mutations: {
    SET_OPTIONS_TABLE_REALISASI(state, payload) {
      state.optionsTable = Object.assign({}, payload);
    },
    SET_LOADING_REALISASI(state, payload) {
      state.isLoading = payload;
    },
    SET_REPORTS_REALISASI(state, payload) {
      state.reports = payload;
    },
    SET_REPORT_REALISASI(state, payload) {
      state.report = payload;
    },
    SET_FORM_KOMODITAS_REALISASI(state, payload) {
      state.form_komoditas = Object.assign({}, payload);
    },
    SET_FORM_KOMODITAS_REALISASI_DEFAULT(state, payload) {
      state.form_komoditas.seri = payload.seri;
      state.form_komoditas.hs = payload.hs;
      state.form_komoditas.uraian = payload.uraian;
      state.form_komoditas.kuota = payload.kuota;
      state.form_komoditas.satuan = payload.satuan;
      state.form_komoditas.kuota_terkecil = payload.kuota_terkecil;
      state.form_komoditas.satuan_terkecil = payload.satuan_terkecil;
      state.form_komoditas.negara_asal = payload.kode_negara_asal;
      state.form_komoditas.pelabuhan_tujuan = payload.kd_pelabuhan_tujuan;
      state.form_komoditas.harga_satuan = payload.harga_satuan;
      state.form_komoditas.rate_nilai_tukar = payload.rate_nilai_tukar;
      state.form_komoditas.kode_kurs = payload.kurs;
      state.form_komoditas.totalharga = payload.total_harga;
    },
    RESET_FORM_KOMODITAS_REALISASI(state) {
      state.form_komoditas = { ...form_komoditas };
    },
    SET_CHECK_STOCK_REALISASI(state, payload) {
      state.check_stock = payload;
    },
    RESET_CHECK_STOCK_REALISASI(state) {
      state.check_stock = {
        is_realisasi: false,
        stock: "",
        stock_terkecil: "",
        sisa_stock: "",
        sisa_stock_terkecil: "",
      };
    },
    SET_DATA_REALISASI_NOT_SENT(state, payload) {
      state.data_realisasi_not_sent = payload;
    },
    SET_DATA_REALISASI(state, payload) {
      state.data_realisasi = payload;
    },
    SET_DATA_REALISASI_PERSENTASI(state, payload) {
      state.data_realisasi_persentasi = payload;
    },
  },
  actions: {
    async GetRealisasi(context) {
      context.commit("SET_LOADING_REALISASI", true);
      try {
        const result = await axios({
          url: `${apiUrl}/iboss/listIjin`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        result.data.data.forEach((item, index) => {
          item.no = index + 1;
          // item.no_ijin = "No. Ijin :" + item.no_ijin;
          item.tgl_daftar = moment(item.tgl_daftar).format("DD-MM-YYYY");
        });

        result.data.data.sort((a, b) => moment(a.tgl_daftar, "DD-MM-YYYY").isBefore(moment(b.tgl_daftar, "DD-MM-YYYY")));

        context.commit("SET_REPORTS_REALISASI", result.data.data);
      } catch (error) {
        catchUnauthorized(error, context);
      } finally {
        context.commit("SET_LOADING_REALISASI", false);
      }
    },
    async GetRealisasiByIdIjin(context, id_ijin) {
      context.commit("SET_LOADING_REALISASI", true);
      try {
        const result = await axios({
          url: `${apiUrl}/iboss/detailijin/${id_ijin}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });
        context.commit("SET_REPORT_REALISASI", result.data.data);
      } catch (error) {
        catchUnauthorized(error, context);
      } finally {
        context.commit("SET_LOADING_REALISASI", false);
      }
    },
    async CheckStockRealisasi(context, payload) {
      context.commit("SET_LOADING_REALISASI", true);
      try {
        const result = await axios({
          url: `${apiUrl}/barang/check-stock/${payload.id_ijin}/${payload.seri}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        const data = result.data.data;
        context.commit("SET_CHECK_STOCK_REALISASI", data);
      } catch (error) {
        catchUnauthorized(error, context);
      } finally {
        context.commit("SET_LOADING_REALISASI", false);
      }
    },
    async AddRealisasiKomoditi(context) {
      context.commit("SET_LOADING_REALISASI", true);
      try {
        await axios({
          url: `${apiUrl}/barang`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
          data: {
            id_ijin: context.state.report.ijin.id_ijin,
            idlayan: context.state.report.ijin.idlayan,
            no_surat_mohon: context.state.report.ijin.no_surat_mohon,
            ...context.state.form_komoditas,
          },
        });

        Swal.fire({
          icon: "success",
          title: "Berhasil",
          text: "Data berhasil ditambahkan",
        });
        return true;
      } catch (error) {
        catchUnauthorized(error, context);

        Swal.fire({
          icon: "error",
          title: "Gagal",
          text: "Data gagal ditambahkan",
        });
      } finally {
        context.commit("SET_LOADING_REALISASI", false);
      }
    },
    async GetDataRealisasiNotSent(context, id_ijin) {
      context.commit("SET_LOADING_REALISASI", true);
      try {
        const result = await axios({
          url: `${apiUrl}/barang/realisasi-not-sent/${id_ijin}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        context.commit("SET_DATA_REALISASI_NOT_SENT", result.data.data);
      } catch (error) {
        catchUnauthorized(error, context);
      } finally {
        context.commit("SET_LOADING_REALISASI", false);
      }
    },
    async GetDataRealisasiPersentasi(context, id_ijin) {
      context.commit("SET_LOADING_REALISASI", true);
      try {
        const result = await axios({
          url: `${apiUrl}/barang/realisasi-persentasi/${id_ijin}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        context.commit("SET_DATA_REALISASI_PERSENTASI", result.data.data);
      } catch (error) {
        catchUnauthorized(error, context);
      } finally {
        context.commit("SET_LOADING_REALISASI", false);
      }
    },
    async SendDataRealisasiToIBOSS(context, id_ijin) {
      context.commit("SET_LOADING_REALISASI", true);
      try {
        const result = await axios({
          url: `${apiUrl}/barang/realisasi-send/${id_ijin}`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });
        // console.log("Result send data IBOSS : ", result.data.data);

        context.dispatch("GetRealisasiByIdIjin", id_ijin);
        context.dispatch("GetDataRealisasiNotSent", id_ijin);
        Swal.fire({
          icon: "success",
          title: "Berhasil",
          text: result.data.data.note,
        });

        return true;
      } catch (error) {
        catchUnauthorized(error, context);

        Swal.fire({
          icon: "error",
          title: "Gagal",
          text: error.response.data.message,
        });
      } finally {
        context.commit("SET_LOADING_REALISASI", false);
      }
    },
    async GetDataRealisasiByIdIjin(context, id_ijin) {
      context.commit("SET_LOADING_REALISASI", true);
      try {
        const result = await axios({
          url: `${apiUrl}/barang/realisasi/${id_ijin}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        context.commit("SET_DATA_REALISASI", result.data.data);
      } catch (error) {
        catchUnauthorized(error, context);
      } finally {
        context.commit("SET_LOADING_REALISASI", false);
      }
    },
    async DeleteRealisasiById(context, id) {
      context.commit("SET_LOADING_REALISASI", true);
      try {
        const result = await axios({
          url: `${apiUrl}/barang/realisasi/${id}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        Swal.fire({
          icon: "success",
          title: "Berhasil",
          text: result.data.message,
        });

        return true;
      } catch (error) {
        catchUnauthorized(error, context);

        Swal.fire({
          icon: "error",
          title: "Gagal",
          text: "Data gagal dihapus",
        });
      } finally {
        context.commit("SET_LOADING_REALISASI", false);
      }
    },
  },
};

export default realisasi;
