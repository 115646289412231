<template>
  <layout-app>
    <HeaderTitle
      title="Satuan Terkecil"
      subtitle="Referensi / Satuan Terkecil"
    />

    <div class="card border-0 p-3">
      <div class="card-body">
        <div class="row justify-content-end">
          <v-text-field
            outlined
            dense
            prepend-inner-icon="mdi-magnify"
            placeholder="Cari..."
            style="max-width: 300px"
            v-model="optionsTable.search"
          />
        </div>
        <v-data-table
          :headers="headers"
          :items="reports"
          :loading="isLoading"
          :options.sync="optionsTable"
          :search="optionsTable.search"
        >
        </v-data-table>
      </div>
    </div>
  </layout-app>
</template>

<script>
import LayoutApp from "@/layouts/layout-app.vue";

export default {
  name: "SatuanTerkecilPage",
  components: {
    LayoutApp,
    HeaderTitle: () => import("@/components/molecules/header-title"),
  },
  data() {
    return {
      headers: [
        { text: "No", value: "no" },
        { text: "Satuan", value: "idsatuan" },
        { text: "Uraian Satuan", value: "uraisatuan" },
      ],
    };
  },
  computed: {
    reports() {
      return this.$store.state.satuanTerkecil.reports;
    },
    isLoading() {
      return this.$store.state.satuanTerkecil.isLoading;
    },
    optionsTable: {
      get() {
        return this.$store.state.satuanTerkecil.optionsTable;
      },
      set(value) {
        this.$store.commit("SET_OPTIONS_TABLE_SATUAN_TERKECIL", value);
      },
    },
  },
  created() {
    this.$store.dispatch("GetSatuanTerkecil");
  },
};
</script>
