import Vue from "vue";
import VueRouter from "vue-router";

import Home from "@/views/home/index.vue";
import Realisasi from "@/views/realisasi/index.vue";
import RealisasiForm from "@/views/realisasi/form.vue";
import Barang from "@/views/barang/index.vue";
import User from "@/views/user/index.vue";
import History from "@/views/history/index.vue";

import KursMataUang from "@/views/referensi/kurs-mata-uang/index.vue";
import Pelabuhan from "@/views/referensi/pelabuhan/index.vue";
import Negara from "@/views/referensi/negara/index.vue";
import Satuan from "@/views/referensi/satuan/index.vue";
import SatuanTerkecil from "@/views/referensi/satuan-terkecil/index.vue";

import Dokumen from "@/views/dokumen/index.vue";

import Login from "@/views/auth/login.vue";
import ChangePassword from "@/views/auth/change-password.vue";

import auth from "../middleware/auth";
import guest from "../middleware/guest";
import log from "../middleware/log";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      middleware: [guest, log],
    },
  },
  {
    path: "/change-password",
    name: "ChangePassword",
    component: ChangePassword,
    meta: {
      middleware: [auth, log],
    },
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    meta: {
      middleware: [auth, log],
    },
  },
  {
    path: "/realisasi",
    name: "Realisasi",
    component: Realisasi,
    meta: {
      middleware: [auth, log],
    },
  },
  {
    path: "/realisasi/form/:id_ijin",
    name: "RealisasiForm",
    component: RealisasiForm,
    meta: {
      middleware: [auth, log],
    },
  },
  {
    path: "/barang",
    name: "Barang",
    component: Barang,
    meta: {
      middleware: [auth, log],
    },
  },
  {
    path: "/user",
    name: "User",
    component: User,
    meta: {
      middleware: [auth, log],
    },
  },
  {
    path: "/history",
    name: "History",
    component: History,
    meta: {
      middleware: [auth, log],
    },
  },
  {
    path: "/referensi/kurs-mata-uang",
    name: "KursMataUang",
    component: KursMataUang,
    meta: {
      middleware: [auth, log],
    },
  },
  {
    path: "/referensi/pelabuhan",
    name: "Pelabuhan",
    component: Pelabuhan,
    meta: {
      middleware: [auth, log],
    },
  },
  {
    path: "/referensi/negara",
    name: "Negara",
    component: Negara,
    meta: {
      middleware: [auth, log],
    },
  },
  {
    path: "/referensi/satuan",
    name: "Satuan",
    component: Satuan,
    meta: {
      middleware: [auth, log],
    },
  },
  {
    path: "/referensi/satuan-terkecil",
    name: "SatuanTerkecil",
    component: SatuanTerkecil,
    meta: {
      middleware: [auth, log],
    },
  },
  {
    path: "/dokumen",
    name: "Dokumen",
    component: Dokumen,
    meta: {
      middleware: [auth, log],
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.path === "/") {
    next({ name: "Home" });
  } else {
    next();
  }
});

function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    context.next(...parameters);
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  }

  return next();
});

export default router;
