<template>
  <layout-app>
    <div class="d-flex flex-column flex-sm-row justify-content-between">
      <HeaderTitle title="Data Barang" subtitle="Data Barang" />
    </div>
    <div class="card border-0 p-3">
      <div class="card-body py-5">
        <!-- <div class="d-flex align-items-center mb-5 mb-sm-0">
          <img
            :src="require('@/assets/icon/ic_barang.svg')"
            width="40"
            alt=""
          />
          <div class="ms-3">
            <p class="mb-0 fs-14 text-muted">Total Stock</p>
            <p class="mb-0 fs-20 fw-bold">
              {{ reports ? format3Digit(reports.total_stock) : 0 }}
            </p>
          </div>
        </div> -->
        <div class="row justify-content-end">
          <div class="col-12 col-sm-5 col-lg-4 col-xl-3">
            <label class="fs-14 fw-medium mb-3">No Ijin</label>
            <v-select
              outlined
              dense
              :items="list_ijin"
              item-text="no_ijin"
              item-value="id_ijin"
              placeholder="Pilih Ijin"
              v-model="filter_ijin"
              @change="handleChangeFilterIjin()"
            />
          </div>
          <div class="col-12 col-sm-5 col-lg-4 col-xl-3">
            <label class="fs-14 fw-medium mb-3">Search</label>
            <v-text-field
              outlined
              dense
              prepend-inner-icon="mdi-magnify"
              placeholder="Cari Barang"
              v-model="optionsTable.search"
            />
          </div>
        </div>
        <v-data-table
          :headers="headers"
          :items="data"
          :loading="isLoading"
          :options.sync="optionsTable"
          :search="optionsTable.search"
          group-by="no_surat_mohon"
          show-expand
          class="table-striped"
        >
          <template v-slot:[`item.persentase`]="{ item }">
            {{ item.persentase }}%
          </template>
          <template v-slot:[`item.persentase_terkecil`]="{ item }">
            {{ item.persentase_terkecil }}%
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  class="btn btn-outline-primary"
                  v-bind="attrs"
                  v-on="on"
                >
                  <span class="fw-light">Action</span>
                  <i class="fa-solid fa-chevron-down small"></i>
                </v-btn>
              </template>
              <v-list min-width="150">
                <v-list-item @click="handleModalHistory(item)">
                  <v-list-item-title class="text-primary text-opacity-75">
                    <i class="fa-solid fa-clock-rotate-left small mr-2"></i>
                    <span class="small">History</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="collapsable">
              <table class="fs-12 fw-medium">
                <tr class="vertical-align-top">
                  <td>Tanggal</td>
                  <td>Volume Sebelum</td>
                  <td>Volume Sebelum (Terkecil)</td>
                  <td>Volume Realisasi</td>
                  <td>Volume Realisasi (Terkecil)</td>
                  <td>Volume Sesudah</td>
                  <td>Volume Sesudah (Terkecil)</td>
                  <td>Kurs PIB</td>
                  <td>File</td>
                </tr>
              </table>

              <table
                v-for="(data, index) in item.history"
                :key="index"
                class="table-stripped"
              >
                <tr>
                  <td>{{ data.created_at }}</td>
                  <td>{{ data.kuota }}</td>
                  <td>{{ data.kuota_terkecil }}</td>
                  <td>{{ data.volume }}</td>
                  <td>{{ data.volume_terkecil }}</td>
                  <td>{{ data.sisa_kuota }}</td>
                  <td>{{ data.sisa_kuota_terkecil }}</td>
                  <td>{{ data.nilai }} ({{ data.kurs_pib }})</td>
                  <td>
                    <v-btn class="btn btn-outline-primary" small>
                      <a :href="linkDownload(data.upload_id)" target="_blank">
                        <i class="fa-solid fa-download"></i>
                      </a>
                    </v-btn>
                  </td>
                </tr>
              </table>
            </td>
          </template>
        </v-data-table>
      </div>
    </div>

    <v-dialog v-model="modalHistory" persistent max-width="1000">
      <History @handleModalHistory="handleModalHistory" />
    </v-dialog>
  </layout-app>
</template>

<script>
import format3Digit from "@/utils/format-3digit";
import LayoutApp from "../../layouts/layout-app.vue";

export default {
  name: "BarangPage",
  components: {
    LayoutApp,
    HeaderTitle: () => import("@/components/molecules/header-title"),
    History: () => import("@/components/organisms/barang-history"),
  },
  data() {
    return {
      headers: [
        { text: "Seri", value: "seri" },
        { text: "HS Code", value: "hs" },
        { text: "Uraian", value: "uraian" },
        { text: "Negara Asal", value: "negara_asal" },
        { text: "Pelabuhan Tujuan", value: "pelabuhan_tujuan" },
        { text: "Kuota", value: "kuota" },
        { text: "Satuan", value: "satuan" },
        { text: "Kuota Terkecil", value: "kuota_terkecil" },
        { text: "Satuan Terkecil", value: "satuan_terkecil" },
        { text: "Realisasi", value: "volume" },
        { text: "Persentase", value: "persentase" },
        { text: "Realisasi Terkecil", value: "volume_terkecil" },
        { text: "Percentase Terkecil", value: "persentase_terkecil" },
        { text: "Action", value: "action", sortable: false },
      ],
      format3Digit,
      data: [],
      filter_ijin: null,
      modalHistory: false,
      baseUrl: "https://api.batam-itinventory.com",
      linkDownload: (upload_id) => `${this.baseUrl}/documents/${upload_id}.pdf`,
    };
  },
  computed: {
    user() {
      return this.$store.state.app.user;
    },
    isLoading() {
      return this.$store.state.barang.isLoading;
    },
    list_ijin() {
      let result = this.$store.state.realisasi.reports;
      let data = [];
      for (const iterator of result) {
        data.push(iterator);
      }

      data.unshift({
        id_ijin: null,
        no_ijin: "Semua Ijin",
      });

      return data;
    },
    reports() {
      return this.$store.state.barang.reports;
    },
    optionsTable: {
      get() {
        return this.$store.state.barang.optionsTable;
      },
      set(value) {
        this.$store.commit("SET_OPTIONS_TABLE_BARANG", value);
      },
    },
  },
  methods: {
    handleModalHistory(item) {
      if (item) this.$store.dispatch("GetBarangHistory", item);
      this.modalHistory = !this.modalHistory;
    },
    handleChangeFilterIjin() {
      if (this.filter_ijin === null) {
        this.data = this.reports;
      } else {
        this.data = this.reports.filter(
          (item) => item.id_ijin === this.filter_ijin
        );
      }
    },
  },
  created() {
    this.$store.dispatch("GetBarang").then(() => {
      this.data = this.reports;
    });
    this.$store.dispatch("GetRealisasi");
  },
};
</script>

<style lang="scss" scoped>
.collapsable {
  padding: 1.5em 1em !important;
  table {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
    text-align: left;
    font-size: 0.9em;
  }
  td,
  th {
    border-bottom: 1px solid gray !important;
    padding: 0.5em 1em !important;
  }

  th {
    color: #848484;
  }
}
</style>
