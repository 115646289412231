import axios from "axios";
import catchUnauthorized from "../../utils/catch-unauthorized";
const apiUrl = process.env.VUE_APP_API_URL;

const home = {
  state: {
    isLoading: false,
    optionsTable: {
      page: 1,
      itemsPerPage: 10,
      search: "",
    },
    data: "",
  },
  mutations: {
    SET_OPTIONS_TABLE_HOME(state, payload) {
      state.optionsTable = Object.assign({}, payload);
    },
    SET_LOADING_HOME(state, payload) {
      state.isLoading = payload;
    },
    SET_DATA_HOME(state, payload) {
      state.data = payload;
    },
  },
  actions: {
    async GetHome(context) {
      context.commit("SET_LOADING_HOME", true);
      try {
        const result = await axios({
          url: `${apiUrl}/dashboard`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        context.commit("SET_DATA_HOME", result.data.data);
      } catch (error) {
        catchUnauthorized(error, context);
      } finally {
        context.commit("SET_LOADING_HOME", false);
      }
    },
  },
};

export default home;
