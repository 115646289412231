import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import app from "./modules/app";

import realisasi from "./modules/realisasi";
import home from "./modules/home";
import barang from "./modules/barang";
import user from "./modules/user";
import history from "./modules/history";

// referensi IBOSS

import kursMataUang from "./modules/kurs-mata-uang";
import pelabuhan from "./modules/pelabuhan";
import negara from "./modules/negara";
import satuan from "./modules/satuan";
import satuanTerkecil from "./modules/satuan-terkecil";

import dokumen from "./modules/dokumen";

export default new Vuex.Store({
  modules: {
    app,
    home,
    realisasi,
    barang,
    user,
    history,
    kursMataUang,
    pelabuhan,
    negara,
    satuan,
    satuanTerkecil,
    dokumen,
  },
});
