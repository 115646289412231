<template>
  <layout-app>
    <HeaderTitle title="History" subtitle="History" />

    <ul class="nav nav-tabs ps-0">
      <li v-for="(item, i) in tabList" :key="i" class="nav-item">
        <a
          :class="`nav-link ${tabActive == item && 'active'}`"
          @click="tabActive = item"
          href="#"
        >
          {{ item }}
        </a>
      </li>
    </ul>
    <div class="card border-0 rounded-t-0 p-3">
      <div class="card-body py-5">
        <div class="row justify-content-end">
          <div class="col-12 col-sm-5 col-lg-4 col-xl-3">
            <v-text-field
              outlined
              dense
              prepend-inner-icon="mdi-magnify"
              placeholder="Cari Barang"
              v-model="optionsTable.search"
            />
          </div>
        </div>
        <v-data-table
          :headers="headers"
          :items="handleFilterTab(reports)"
          :loading="isLoading"
          :options.sync="optionsTable"
          :search="optionsTable.search"
        >
          <template v-slot:[`item.created_at`]="{ item }">
            {{ moment(item.created_at).format("DD/MM/YYYY") }}
          </template>
          <template v-slot:[`item.kuota`]="{ item }">
            {{ item.kuota }} ({{ item.satuan }})
          </template>
          <template v-slot:[`item.kuota_terkecil`]="{ item }">
            {{ item.kuota_terkecil }} ({{ item.satuan_terkecil }})
          </template>
          <template v-slot:[`item.volume`]="{ item }">
            {{ item.volume }} ({{ item.satuan }})
          </template>
          <template v-slot:[`item.volume_terkecil`]="{ item }">
            {{ item.volume_terkecil }} ({{ item.satuan_terkecil }})
          </template>
          <template v-slot:[`item.nilai`]="{ item }">
            {{ item.nilai }} ({{ item.kurs_pib }})
          </template>
          <template v-slot:[`item.upload_id`]="{ item }">
            <a :href="linkDownload(item.upload_id)" target="_blank">
              <v-btn class="btn btn-outline-primary" small>
                <i class="fa-solid fa-download"></i>
              </v-btn>
            </a>
          </template>
        </v-data-table>
      </div>
    </div>
  </layout-app>
</template>

<script>
import moment from "moment";
import LayoutApp from "../../layouts/layout-app.vue";

export default {
  name: "HistoryPage",
  components: {
    LayoutApp,
    HeaderTitle: () => import("@/components/molecules/header-title"),
  },
  data() {
    return {
      headers: [
        { text: "No. Surat Permohonan", value: "no_surat_mohon" },
        { text: "Tanggal", value: "created_at" },
        { text: "Seri", value: "seri" },
        { text: "HS Code", value: "hs" },
        { text: "Uraian", value: "uraian" },
        { text: "Negara Asal", value: "negara_asal" },
        { text: "Pelabuhan Tujuan", value: "pelabuhan_tujuan" },
        {
          text: "Volume Sesuai Kuota (Dalam satuan Besar) /Satuan",
          value: "kuota",
        },
        {
          text: "Volume Sesuai Kuota (Dalam satuan Kecil) /Satuan",
          value: "kuota_terkecil",
        },
        {
          text: "Realisasi (dalam satuan besar) /Satuan",
          value: "volume",
        },
        {
          text: "Realisasi (dalam satuan kecil) /Satuan",
          value: "volume_terkecil",
        },
        { text: "Nilai Sesuai PIB /Kurs", value: "nilai" },
        { text: "File", value: "upload_id" },
      ],
      baseUrl: "https://api.batam-itinventory.com",
      linkDownload: (upload_id) => `${this.baseUrl}/documents/${upload_id}.pdf`,
      moment,
      tabList: ["All", "Pemasukan", "Pengeluaran"],
      tabActive: "All",
    };
  },
  computed: {
    reports() {
      return this.$store.state.history.reports;
    },
    isLoading() {
      return this.$store.state.history.isLoading;
    },
    optionsTable: {
      get() {
        return this.$store.state.history.optionsTable;
      },
      set(value) {
        this.$store.commit("SET_OPTIONS_TABLE_HISTORY", value);
      },
    },
  },
  methods: {
    handleFilterTab(data) {
      if (this.tabActive == "Pemasukan")
        return data.filter((item) => item.type_history == "Pemasukan");
      if (this.tabActive == "Pengeluaran")
        return data.filter((item) => item.type_history == "Pengeluaran");

      return data;
    },
  },
  created() {
    this.$store.dispatch("GetAllHistory");
  },
};
</script>
