import axios from "axios";
import catchUnauthorized from "../../utils/catch-unauthorized";
const apiUrl = process.env.VUE_APP_API_URL;
import moment from "moment";
import Swal from "sweetalert2";

const form = {
  document_number: null,
  document_date: moment().format("YYYY-MM-DD"),
  document_expire: moment().format("YYYY-MM-DD"),
  keterangan: "-",
  document_issued: "-",
  document_id: 240,
  file: null,
};

const dokumen = {
  state: {
    isLoading: false,
    optionsTable: {
      page: 1,
      itemsPerPage: 10,
      search: "",
    },
    reports: [],
    list_jenis: [
      {
        id: 240,
        nama: "PIB",
      },
      {
        id: 241,
        nama: "PEB",
      },
    ],
    form: { ...form },
  },
  mutations: {
    SET_OPTIONS_TABLE_DOKUMEN(state, payload) {
      state.optionsTable = Object.assign({}, payload);
    },
    SET_LOADING_DOKUMEN(state, payload) {
      state.isLoading = payload;
    },
    SET_REPORTS_DOKUMEN(state, payload) {
      state.reports = payload;
    },
    SET_FORM_DOKUMEN(state, payload) {
      state.form[payload.key] = payload.value;
    },
    RESET_FORM_DOKUMEN(state) {
      state.form = { ...form };
    },
  },
  actions: {
    async GetDokumen(context) {
      context.commit("SET_LOADING_DOKUMEN", true);
      try {
        const result = await axios({
          url: `${apiUrl}/iboss/listFile`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        result.data.data.forEach((item, index) => {
          item.no = index + 1;
        });

        context.commit("SET_REPORTS_DOKUMEN", result.data.data);
      } catch (error) {
        catchUnauthorized(error, context);
      } finally {
        context.commit("SET_LOADING_DOKUMEN", false);
      }
    },
    async UploadDokumen(context) {
      context.commit("SET_LOADING_DOKUMEN", true);
      try {
        const form = context.state.form;

        const formData = new FormData();
        formData.append("document_number", form.document_number);
        formData.append("document_date", form.document_date);
        formData.append("document_expire", form.document_expire);
        formData.append("keterangan", form.keterangan);
        formData.append("document_issued", form.document_issued);
        formData.append("document_id", form.document_id);
        formData.append("file", form.file);

        await axios({
          url: `${apiUrl}/iboss/upload_file`,
          method: "post",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
          data: formData,
        });

        Swal.fire({
          icon: "success",
          title: "Berhasil",
          text: "Dokumen berhasil diupload",
        });

        context.dispatch("GetDokumen");
        return true;
      } catch (error) {
        console.log(error);
        catchUnauthorized(error, context);

        Swal.fire({
          icon: "error",
          title: "Gagal",
          text: error.response.data.message,
        });
      } finally {
        context.commit("SET_LOADING_DOKUMEN", false);
      }
    },
  },
};

export default dokumen;
