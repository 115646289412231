import axios from "axios";
import catchUnauthorized from "../../utils/catch-unauthorized";
const apiUrl = process.env.VUE_APP_API_URL;
import Swal from "sweetalert2";

const user = {
  state: {
    isLoading: false,
    optionsTable: {
      page: 1,
      itemsPerPage: 10,
      search: "",
    },
    reports: [],
    form: {
      name: "",
      role: "",
      address: "",
      email: "",
      npwp: "",
      password: "",
      is_active: true,
    },
    isUpdate: false,
  },
  mutations: {
    SET_OPTIONS_TABLE_USER(state, payload) {
      state.optionsTable = Object.assign({}, payload);
    },
    SET_LOADING_USER(state, payload) {
      state.isLoading = payload;
    },
    SET_REPORTS_USER(state, payload) {
      state.reports = payload;
    },
    SET_FORM_USER(state, payload) {
      state.form[payload.key] = payload.value;
    },
    SET_IS_UPDATE_USER(state, payload) {
      state.isUpdate = payload;
    },
  },
  actions: {
    async GetAllUsers(context) {
      context.commit("SET_LOADING_USER", true);
      try {
        const result = await axios({
          url: `${apiUrl}/user`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        result.data.data.forEach((item, index) => {
          item.no = index + 1;
        });

        context.commit("SET_REPORTS_USER", result.data.data);
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_LOADING_USER", false);
      }
    },
    async CreateUser(context) {
      context.commit("SET_LOADING_USER", true);
      try {
        await axios({
          url: `${apiUrl}/register`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
          data: context.state.form,
        });

        context.dispatch("GetAllUsers");

        Swal.fire({
          title: "Berhasil",
          text: "Data berhasil disimpan",
          icon: "success",
        });

        return true;
      } catch (error) {
        catchUnauthorized(error);

        Swal.fire({
          title: "Gagal",
          text: error.response.data.message,
          icon: "error",
        });
      } finally {
        context.commit("SET_LOADING_USER", false);
      }
    },
    async SetFormUpdateUser(context, id) {
      context.commit("SET_LOADING_USER", true);
      try {
        const result = await axios({
          url: `${apiUrl}/user/${id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });

        context.state.form = {
          name: result.data.data.name,
          role: result.data.data.role,
          address: result.data.data.address,
          email: result.data.data.email,
          npwp: result.data.data.npwp,
          is_active: result.data.data.is_active,
        };
      } catch (error) {
        catchUnauthorized(error);
      } finally {
        context.commit("SET_LOADING_USER", false);
      }
    },
    async UpdateUser(context, id) {
      context.commit("SET_LOADING_USER", true);
      try {
        await axios({
          url: `${apiUrl}/user/${id}`,
          method: "PUT",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
          data: context.state.form,
        });

        context.dispatch("GetAllUsers");

        Swal.fire({
          title: "Berhasil",
          text: "Data berhasil disimpan",
          icon: "success",
        });

        return true;
      } catch (error) {
        catchUnauthorized(error);

        Swal.fire({
          title: "Gagal",
          text: error.response.data.message,
          icon: "error",
        });
      } finally {
        context.commit("SET_LOADING_USER", false);
      }
    },
    async DeleteUser(context, id) {
      context.commit("SET_LOADING_USER", true);
      try {
        await axios({
          url: `${apiUrl}/user/${id}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${context.rootState.app.token}`,
          },
        });
        context.dispatch("GetAllUsers");
        Swal.fire({
          title: "Berhasil",
          text: "Data berhasil dihapus",
          icon: "success",
        });
      } catch (error) {
        catchUnauthorized(error);
        Swal.fire({
          title: "Gagal",
          text: error.response.data.message,
          icon: "error",
        });
      } finally {
        context.commit("SET_LOADING_USER", false);
      }
    },
  },
};

export default user;
