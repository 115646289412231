<template>
  <layout-app>
    <HeaderTitle
      :title="`REALISASI ${ijin?.no_surat_mohon}`"
      :subtitle="`Realisasi / ${ijin?.urailayan}`"
    />

    <v-card class="card border-0 p-3 shadow-none" :loading="isLoading">
      <div class="card-body">
        <WizardStepper :items="steppers" v-model="step" />
        <hr />
        <br />
        <section>
          <DataIzinUsaha v-if="step === 1" />
          <BuatRealisasi v-if="step === 2" />
          <DataRealisasi v-if="step === 3" />
          <KirimRealisasi v-if="step === 4" />
          <PresentasiRealisasi v-if="step === 5" />
        </section>
      </div>
    </v-card>
  </layout-app>
</template>

<script>
import LayoutApp from "@/layouts/layout-app.vue";

export default {
  name: "SatuanPage",
  components: {
    LayoutApp,
    HeaderTitle: () => import("@/components/molecules/header-title"),
    WizardStepper: () => import("@/components/molecules/wizard-stepper"),
    DataIzinUsaha: () => import("./form-stepper/1-data-izin-usaha-kawasan.vue"),
    BuatRealisasi: () => import("./form-stepper/2-buat-realisasi.vue"),
    DataRealisasi: () => import("./form-stepper/3-data-realisasi.vue"),
    KirimRealisasi: () => import("./form-stepper/4-kirim-realisasi.vue"),
    PresentasiRealisasi: () =>
      import("./form-stepper/5-presentasi-realisasi.vue"),
  },
  data() {
    return {
      steppers: [
        {
          title: "Data Izin Usaha Kawasan",
          icon: "fa-solid fa-file-invoice",
        },
        {
          title: "Buat Realisasi",
          icon: "fa-solid fa-file-signature",
        },
        {
          title: "Data Realisasi",
          icon: "fa-solid fa-cloud-arrow-up",
        },
        {
          title: "Kirim Realisasi",
          icon: "fa-solid fa-paper-plane",
        },
        {
          title: "Persentase Realisasi",
          icon: "fa-solid fa-percent",
        },
      ],
      step: 1,
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.realisasi.isLoading;
    },
    ijin() {
      return this.$store.state.realisasi.report.ijin;
    },
  },
  methods: {},
  created() {
    this.$store.dispatch("GetRealisasiByIdIjin", this.$route.params.id_ijin);
  },
};
</script>
